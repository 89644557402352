
import { Vue, Component, Emit, Prop } from "vue-property-decorator";
import useParticipantForm from "@/composables/use-participant-form";
// eslint-disable-next-line no-unused-vars
import { ParticipantInfoResponseData } from "@/services/room.service";
import useRoleOrganization from "@/composables/use-role-organization";

@Component
export default class ParticipateEditor extends Vue {
  @Prop()
  private participantInfo?: ParticipantInfoResponseData;

  @Prop({
    type: Boolean,
    default: false
  })
  private isUpdate?: boolean;

  @Emit("submit-participate")
  onSubmitParticipant(): void {}

  @Emit("update-participate")
  onUpdateParticipate(): void {}

  setup(props: { participantInfo: ParticipantInfoResponseData }) {
    const { participant } = useParticipantForm({
      participantInfo: props.participantInfo
    });

    const { roles } = useRoleOrganization();
    return { participant, roles };
  }
}

import RoomService, {
  CreateParticipant,
  ParticipantInfoResponseData
} from "@/services/room.service";
import { onMounted, reactive, toRefs } from "@vue/composition-api";
interface UseRoomParticipateState {
  loading: boolean;
  participants: ParticipantInfoResponseData[];
  selectedParticipant?: ParticipantInfoResponseData;
  participantActive: string[];
  newParticipant: CreateParticipant[];
  showCreatePopup: boolean;
  showUpdatePopup: boolean;
  showParticipantEditor: boolean;
  showRoomEditor: boolean;
}
interface UseRoomParticipateParams {
  roomId: string;
}
const useRoomParticipate = ({ roomId }: UseRoomParticipateParams) => {
  const state = reactive<UseRoomParticipateState>({
    loading: true,
    participants: [],
    selectedParticipant: undefined,
    participantActive: [],
    newParticipant: [],
    showCreatePopup: false,
    showUpdatePopup: false,
    showParticipantEditor: true,
    showRoomEditor: false
  });

  const fetchAllParticipant = async () => {
    state.loading = true;
    const data = await RoomService.getAllParticipantByRoomId(roomId);
    if (data.length) {
      state.participantActive = [`participant-${data[0].participantId}`];

      state.participants = data;
    }
    state.loading = false;
  };

  const addParticipantToList = async (participant: CreateParticipant) => {
    state.newParticipant.push(participant);
    state.showParticipantEditor = false;
  };

  const addParticipant = async () => {
    const res = await RoomService.addParticipantByRoomId(
      roomId,
      state.newParticipant
    );

    if (res) {
      toggleCreatePopup();
      fetchAllParticipant();
      state.newParticipant = [];
    }
  };

  const editParticipant = async () => {
    if (state.selectedParticipant) {
      const { participantId, name, avatar } = state.selectedParticipant;
      await RoomService.editParticipantByRoomId(
        roomId,
        participantId,
        {
          name,
          avatar
        }
      );
    }
    toggleUpdatePopup();
    fetchAllParticipant();
  };

  const removeParticipant = async (participantId: string) => {
    const res = await RoomService.removeParticipantByRoomId(
      roomId,
      participantId
    );
    if (res) {
      fetchAllParticipant();
    }
  };

  const toggleCreatePopup = async () => {
    state.showCreatePopup = !state.showCreatePopup;
    if (!state.showCreatePopup) {
      state.newParticipant = [];
    }
  };

  const toggleShowEditor = () => {
    state.showParticipantEditor = !state.showParticipantEditor;
  };

  const toggleUpdatePopup = () => {
    state.showUpdatePopup = !state.showUpdatePopup;
  };

  const changeSelectedParticipant = (
    participate: ParticipantInfoResponseData
  ) => {
    toggleUpdatePopup();
    state.selectedParticipant = participate;
  };

  const toggleRoomEditor = () => {
    state.showRoomEditor = !state.showRoomEditor;
  };
  onMounted(() => {
    fetchAllParticipant();
  });

  return {
    ...toRefs(state),
    addParticipantToList,
    addParticipant,
    editParticipant,
    changeSelectedParticipant,
    toggleCreatePopup,
    toggleUpdatePopup,
    toggleShowEditor,
    removeParticipant,
    toggleRoomEditor
  };
};

export default useRoomParticipate;

import {
  CreateParticipant,
  ParticipantInfoResponseData
} from "@/services/room.service";
import { onMounted, reactive, toRefs } from "@vue/composition-api";

interface UseParticipantFormParams {
  participantInfo?: ParticipantInfoResponseData;
}
interface UseParticipantFormState {
  participant: CreateParticipant;
}
const useParticipantForm = ({ participantInfo }: UseParticipantFormParams) => {
  const state = reactive<UseParticipantFormState>({
    participant: {}
  });

  const clearForm = () => {
    state.participant = {};
  };

  onMounted(() => {
    if (participantInfo) {
      state.participant.roleId = participantInfo.roleId;
      state.participant.email = participantInfo.email;
      state.participant.name = participantInfo.name;
      state.participant.avatar = participantInfo.avatar;
      state.participant.exitPath = participantInfo.exitPath;
    } else {
      state.participant.avatar =
        "https://www.globish.co.th/front/img/globish-logo-temp.png";
    }
  });

  return { ...toRefs(state), clearForm };
};

export default useParticipantForm;

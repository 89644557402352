
import { Vue, Component } from "vue-property-decorator";
import useRoomDetail from "@/composables/room/use-room-detail.ts";
import useRoomParticipate from "@/composables/room/use-room-participate.ts";
import ParticipateEditor from "@/components/ParticipateEditor.vue";
import RoomDescription from "@/components/room/RoomDescription.vue";
import RoomEditor from "@/components/RoomEditor.vue";

@Component({ components: { ParticipateEditor, RoomDescription, RoomEditor } })
export default class Participant extends Vue {
  [x: string]: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setup(props: any, context: any) {
    const { room, fetchRoom } = useRoomDetail({
      roomId: context.root.$route.params.id
    });
    const {
      loading,
      newParticipant,
      participants,
      participantActive,
      selectedParticipant,
      showCreatePopup,
      showUpdatePopup,
      showParticipantEditor,
      showRoomEditor,
      addParticipant,
      addParticipantToList,
      editParticipant,
      changeSelectedParticipant,
      toggleCreatePopup,
      toggleUpdatePopup,
      toggleShowEditor,
      toggleRoomEditor,
      removeParticipant
    } = useRoomParticipate({
      roomId: context.root.$route.params.id
    });

    return {
      // useRoomDetail
      room,
      fetchRoom,
      // useRoomParticipate
      loading,
      newParticipant,
      participants,
      participantActive,
      selectedParticipant,
      showCreatePopup,
      showUpdatePopup,
      showParticipantEditor,
      showRoomEditor,
      addParticipant,
      addParticipantToList,
      editParticipant,
      changeSelectedParticipant,
      toggleCreatePopup,
      toggleUpdatePopup,
      toggleShowEditor,
      toggleRoomEditor,
      removeParticipant
    };
  }

  copyLinkSuccess() {
    this.$message.success("Copied");
  }

  updateRoomNotifyError(msg: string) {
    this.$notification.error({
      message: "Fail!",
      description: msg || "",
      duration: 0
    });
  }

  success() {
    this.$message.success("Updated Successfull");
    this.toggleRoomEditor();
    this.fetchRoom();
  }
}
